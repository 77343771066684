// Defines a custom typography configuration

$ca-typography-config: (
    brand-family: 'Inter',
    plain-family: 'Inter',
    bold-weight: 600,
    use-system-variables: true,
    system-variables-prefix: ca-material
);

/**
  Possible level names are: body-large, body-medium, body-small, display-large, display-medium,
  display-small, headline-large, headline-medium, headline-small, label-large, label-medium,
  label-small, title-large, title-medium, title-small.
  */
@mixin typography-level($level-name, $size, $line-height, $weight, $font: 'Inter', $tracking: 0) {
    --ca-material-#{$level-name}: #{$weight} #{$size} / #{$line-height} #{$font} !important;
    --ca-material-#{$level-name}-font: #{$font};
    --ca-material-#{$level-name}-line-height: #{$line-height};
    --ca-material-#{$level-name}-size: #{$size};
    // --ca-material-#{$level-name}-tracking: $tracking;
    --ca-material-#{$level-name}-weight: #{$weight};
}

/**
  Specific table typography level names: mat-table-row-item-label-text.
  */
@mixin table-typography-level($level-name, $size, $line-height, $weight, $font: 'Inter', $tracking: 0) {
    --#{$level-name}-size: #{$size};
    --#{$level-name}-weight: #{$weight};
}

@mixin ca-typography-sm() {
    @include typography-level(display-large, 24px, 32px, 500);
    @include typography-level(display-medium, 20px, 28px, 500);
    @include typography-level(display-small, 18px, 26px, 500);
    @include typography-level(headline-large, 16px, 24px, 500);
    @include typography-level(headline-medium, 14px, 20px, 500);
    @include typography-level(headline-small, 12px, 18px, 500);
    // @include typography-level(title-large, 1rem, 1.5rem, 400);
    // @include typography-level(title-medium, 1rem, 1.5rem, 400);
    // @include typography-level(title-small, 1rem, 1.5rem, 400);
    @include typography-level(body-large, 16px, 24px, 400);
    @include typography-level(body-medium, 14px, 21px, 400);
    @include typography-level(body-small, 12px, 18px, 400);
    @include typography-level(label-large, 16px, 24px, 400);
    @include typography-level(label-medium, 14px, 21px, 400);
    @include typography-level(label-small, 12px, 18px, 400);
    @include table-typography-level(mat-table-row-item-label-text, 14px, 21px, 400);
}

@mixin ca-typography-md() {
    @include typography-level(display-large, 32px, 44px, 500);
    @include typography-level(display-medium, 24px, 32px, 500);
    @include typography-level(display-small, 20px, 28px, 500);
    @include typography-level(headline-large, 18px, 24px, 500);
    @include typography-level(headline-medium, 16px, 20px, 500);
    @include typography-level(headline-small, 12px, 18px, 500);
    // @include typography-level(title-large, 1rem, 1.5rem, 400);
    // @include typography-level(title-medium, 1rem, 1.5rem, 400);
    // @include typography-level(title-small, 1rem, 1.5rem, 400);
    @include typography-level(body-large, 16px, 24px, 400);
    @include typography-level(body-medium, 14px, 21px, 400);
    @include typography-level(body-small, 12px, 18px, 400);
    @include typography-level(label-large, 16px, 24px, 400);
    @include typography-level(label-medium, 14px, 21px, 400);
    @include typography-level(label-small, 12px, 18px, 400);
    @include table-typography-level(mat-table-row-item-label-text, 16px, 24px, 400);
}

@mixin ca-typography-lg() {
    @include typography-level(display-large, 32px, 44px, 500);
    @include typography-level(display-medium, 24px, 32px, 500);
    @include typography-level(display-small, 20px, 28px, 500);
    @include typography-level(headline-large, 18px, 24px, 500);
    @include typography-level(headline-medium, 16px, 20px, 500);
    @include typography-level(headline-small, 14px, 20px, 500);
    // @include typography-level(title-large, 1rem, 1.5rem, 400);
    // @include typography-level(title-medium, 1rem, 1.5rem, 400);
    // @include typography-level(title-small, 1rem, 1.5rem, 400);
    @include typography-level(body-large, 16px, 24px, 400);
    @include typography-level(body-medium, 14px, 21px, 400);
    @include typography-level(body-small, 12px, 18px, 400);
    @include typography-level(label-large, 16px, 24px, 400);
    @include typography-level(label-medium, 14px, 21px, 400);
    @include typography-level(label-small, 12px, 18px, 400);
    @include table-typography-level(mat-table-row-item-label-text, 16px, 24px, 400);
}

@mixin ca-typography() {
    --ca-font-family: 'Inter';
    --ca-headline-font-weight: 600;

    $headlineFontWeight: var(--ca-headline-font-weight);
    $fontFamily: var(--ca-font-family);

    @media screen and (max-width: $md) {
        @include ca-typography-sm();

        /**
        * TODO: Move it to new shared ca-ui library once it is created.
        */
        --ca-headline-1-font: #{$headlineFontWeight} 24px / 1.25 #{$fontFamily};
        --ca-headline-2-font: #{$headlineFontWeight} 22px / 1.25 #{$fontFamily};
        --ca-headline-3-font: #{$headlineFontWeight} 20px / 1.33 #{$fontFamily};
        --ca-headline-4-font: #{$headlineFontWeight} 18px / 1.33 #{$fontFamily};
        --ca-headline-5-font: #{$headlineFontWeight} 16px / 1.44 #{$fontFamily};
        --ca-text-lg-font: normal 18px / 1.78 #{$fontFamily};
        --ca-text-font: normal 16px / 1.63 #{$fontFamily};
        --ca-text-sm-font: normal 14px / 1.5 #{$fontFamily};
    }

    @media screen and (min-width: $md) {
        @include ca-typography-md();

        /**
        * TODO: Move it to new shared ca-ui library once it is created.
        */
        --ca-headline-1-font: #{$headlineFontWeight} 32px / 1.25 #{$fontFamily};
        --ca-headline-2-font: #{$headlineFontWeight} 28px / 1.25 #{$fontFamily};
        --ca-headline-3-font: #{$headlineFontWeight} 24px / 1.33 #{$fontFamily};
        --ca-headline-4-font: #{$headlineFontWeight} 21px / 1.33 #{$fontFamily};
        --ca-headline-5-font: #{$headlineFontWeight} 18px / 1.44 #{$fontFamily};
        --ca-text-lg-font: normal 18px / 1.78 #{$fontFamily};
        --ca-text-font: normal 16px / 1.63 #{$fontFamily};
        --ca-text-sm-font: normal 14px / 1.5 #{$fontFamily};
    }

    @media screen and (min-width: $lg) {
        @include ca-typography-lg();

        /**
        * TODO: Move it to new shared ca-ui library once it is created.
        */
        --ca-headline-1-font: #{$headlineFontWeight} 32px / 1.25 #{$fontFamily};
        --ca-headline-2-font: #{$headlineFontWeight} 28px / 1.25 #{$fontFamily};
        --ca-headline-3-font: #{$headlineFontWeight} 24px / 1.33 #{$fontFamily};
        --ca-headline-4-font: #{$headlineFontWeight} 21px / 1.33 #{$fontFamily};
        --ca-headline-5-font: #{$headlineFontWeight} 18px / 1.44 #{$fontFamily};
        --ca-text-lg-font: normal 18px / 1.78 #{$fontFamily};
        --ca-text-font: normal 16px / 1.63 #{$fontFamily};
        --ca-text-sm-font: normal 14px / 1.5 #{$fontFamily};
    }

    .ca-bold {
        font-weight: 600 !important;
    }

    /**
    * TODO: Move it to new shared ca-ui library once it is created.
    */
    .ca-headline-1 {
        font: var(--ca-headline-1-font);
    }

    .ca-headline-2 {
        font: var(--ca-headline-2-font);
    }

    .ca-headline-3 {
        font: var(--ca-headline-3-font);
    }

    .ca-headline-4 {
        font: var(--ca-headline-4-font);
    }

    .ca-headline-5 {
        font: var(--ca-headline-5-font);
    }

    .ca-text-lg {
        font: var(--ca-text-lg-font);
    }

    .ca-text {
        font: var(--ca-text-font);
    }

    .ca-text-sm {
        font: var(--ca-text-sm-font);
    }

    .ca-text-primary {
        color: var(--ca-grey-0);
    }

    .ca-text-secondary {
        color: var(--ca-grey-10);
    }

    .ca-text-tertiary {
        color: var(--ca-grey-50);
    }
}
